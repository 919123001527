<template>
  <div class="purchases-table-wrapper">
    <div class="purchases-table">
      <div class="purchases-table__header">
        <div class="purchases-table__header-item wd--id">ID</div>
        <div class="purchases-table__header-item wd--email">E-mail</div>
        <div class="purchases-table__header-item wd--name">Имя</div>
        <div class="purchases-table__header-item wd--registration">Дата регистрации</div>
        <div class="purchases-table__header-item wd--utm-markers">UTM-метки</div>
      </div>
      <template v-if="dataTable && dataTable.length > 0">
        <div class="purchases-table__row-wrapper" v-for="item in dataTable" :key="item.id">
          <div class="purchases-table__row-user-info">
            <div style="display: flex">
              <div class="purchases-table__row-item wd--id">{{ item.id }}</div>
              <div class="purchases-table__row-item wd--email">{{ item.email }}</div>
              <div class="purchases-table__row-item wd--name">{{ item.fullName }}</div>
              <div class="purchases-table__row-item wd--registration">{{ new Date(item.dateOfRegistration * 1000).toLocaleDateString() }}</div>
            </div>
            <user-course-table :data-courses="item.courses"/>
          </div>

          <div class="wd--utm-markers">
            <template v-if="item.utmMarks !== null && item.utmMarks.length > 0">
              <div class="purchases-table__row-utm-info" v-for="(utm, index) in item.utmMarks" :key="index">
                <div v-if="utm && utm.name">{{ utm.name }}</div>
                <div v-else>-</div>
                <div v-if="utm && utm.value">{{ utm.value }}</div>
                <div v-else>-</div>
              </div>
            </template>
            <template v-else>
              <div class="purchases-table__row-utm-info purchases-table__row-utm-info--empty">
                Отсутствуют
              </div>

            </template>
          </div>

        </div>
      </template>
      <div v-else class="purchases-table__empty">
        Нет данных
      </div>
    </div>

      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pagination.limit"
          :current-page="pagination.page"
          layout="prev, pager, next, jumper, sizes, total"
          :total="pagination.total">
      </el-pagination>
  </div>
</template>

<script>
import UserCourseTable from "./components/UserCourseTable";

export default {
  name: "PurchasesTable",
  props:{
    dataTable:{type: Array},
    pagination: {type: Object}
  },
  components: {
    UserCourseTable
  },
  methods: {
    handleSizeChange(value) {
      this.pagination.limit = value
      this.$emit('updatePagination')
    },
    handleCurrentChange(value) {
      this.pagination.page = value
      this.$emit('updatePagination')
    }
  }
}
</script>

<style lang="scss">
.purchases-table-wrapper {
  .el-pagination {
    margin: 30px auto;
    padding-bottom: 30px;
    width: max-content;

    .el-select .el-input {
      width: 130px;
      margin: 0 5px;
    }

    li.number{
      margin: 0 5px;
      background-color: #f4f4f5;
      color: #606266;
      min-width: 30px;
      border-radius: 2px;
    }

    li.number.active {
      background-color: #409EFF;
      color: #FFF;
    }

    .btn-prev, .btn-next {
      padding: 0;
      margin: 0 5px;
      background-color: #f4f4f5;
      color: #606266;
      min-width: 30px;
      border-radius: 2px;

      &:disabled {
        color: #C0C4CC;
      }
    }
  }
}

.purchases-table {
  margin-top: 26px;
  min-height: 400px;

  &__empty {
    line-height: 60px;
    width: max-content;
    margin: 100px auto;
    color: #909399;
  }

  &__header {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 28px;
  }

  &__header-item {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #2A2A2A;
    padding: 0 4px;
  }

  &__row-wrapper {
    display: flex;
    padding-top: 24px;
    padding-bottom: 20px;
    background: #FBFBFB;

    padding-left: 28px;
    border-bottom: 1px solid #f3f1f1;
    transition: all 0.2s;

    &:hover {
      background: #F6F7FB !important;

      .user-course-table {
        .el-table {
          background: #F6F7FB;

          &:before {
            background: #F6F7FB;
          }

          tr {
            background: #F6F7FB;
          }
        }

        thead {
          th {
            background: #F6F7FB;
          }
        }
      }

    }

  }

  &__row-item {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    word-break: break-word;
    text-align: left;
    color: #121212;

    padding: 0 4px;
  }

  &__row-utm-info {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    word-break: break-word;
    color: #707070;
    margin-bottom: 12px;

    &--empty {
      font-size: 13px;
    }
    div:last-child {
      font-size: 14px;
      line-height: 20px;
      color: #121212;
    }
  }


  &__courses-header {
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    transition: 0.2s;

    span {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #1454F2;

      margin-left: 12px;
    }
  }

  &__courses-item {
    display: flex;
    align-items: center;

    padding-left: 20px;
    margin-bottom: 4px;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;

    img {
      margin-left: -3px;
      margin-right: 6px;
    }
  }

  &__courses-delimiter {
    margin-right: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #1454F2;
  }

  .wd--id {
    width: 200px;
  }

  .wd--email {
    width: 245px;

  }

  .wd--name {
    width: 245px;

  }

  .wd--registration {
    width: 200px;

  }

  .wd--utm-markers {
    width: 200px;
  }
}
</style>