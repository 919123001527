import {purchasesRepository} from "./purchases.repository";
import {notificationsHelper} from "../../../helpers/notifications.helper";
import {timeDate} from "../../../utils/timeDate";
// import {timeDate} from "../../../utils/timeDate";
class PurchasesService {
    async getUtmMarks(name) {
        try {
            const utmData = await purchasesRepository.getUtmMarks(name)
            return utmData
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.error(error)
        }
    }

    async getPurchasesPage(filters, pagination) {
        try {
            const params = {
                utm_source: filters.utm_source ? filters.utm_source : null,
                utm_medium: filters.utm_medium ? filters.utm_medium : null,
                utm_campaign: filters.utm_campaign ? filters.utm_campaign : null,
                utm_content: filters.utm_content ? filters.utm_content : null,
                from_:  Math.floor(filters.dateRange[0] / 1000),
                to: Math.floor(timeDate.toLastTime(filters.dateRange[1])/1000),
                page: pagination.page,
                limit: pagination.limit
            }

            const purchasesPage = await purchasesRepository.getPurchasesPage(params)
            return purchasesPage
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.error(error)
        }
    }

    async getPurchasePrice(filters) {
        try {
            const params = {
                utm_source: filters.utm_source ? filters.utm_source : null,
                utm_medium: filters.utm_medium ? filters.utm_medium : null,
                utm_campaign: filters.utm_campaign ? filters.utm_campaign : null,
                utm_content: filters.utm_content ? filters.utm_content : null,
                from_:  Math.floor(filters.dateRange[0] / 1000),
                to: Math.floor(timeDate.toLastTime(filters.dateRange[1])/1000)
            }

            const purchasesPrice = await purchasesRepository.getPurchasePrice(params)
            return purchasesPrice
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.error(error)
        }
    }
}


export const purchasesService = new PurchasesService()