<template>
  <div>
    <el-select v-model="currentValue"
               :disabled="disabled"
               :placeholder="placeholder"
               :multiple="multiply"
               :clearable="clearable"
               filterable
               class="purchases-select"
               popper-class="purchases-select__popper"
               @change="changeValue">
      <el-option
          v-for="item in options"
          :key="item"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
  </div>

</template>

<script>
export default {
  name: "SelectInput",
  props: {
    value: {
      type: [Number, String, Boolean, Array],
      default: ''
    },
    options: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    multiply: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true,
    }
  },
  created() {
    this.currentValue = this.value
  },
  data() {
    return {
      currentValue: ''
    }
  },
  methods: {
    changeValue: function () {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    }
  }
}
</script>

<style lang="scss">
.purchases-select {
  .el-input__inner {
    width: 226px;
    height: 52px;

    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 100px;

    padding-left: 18px;

    &::placeholder {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

    }
  }

  .el-icon-arrow-up:before {
    content: "\e78f";
    font-size: 16px;
  }
}

.purchases-select__popper{
  max-width: 400px;
  .popper__arrow{
    left: 50% !important;
  }
  .el-select-dropdown__item {
    font-family: Roboto, sans-serif;
    word-break: break-word;
    white-space: normal;
    height: auto;
  }
  .el-select-dropdown__item.selected {
    font-weight: 400;
  }
}
</style>