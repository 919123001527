import http from '@/app/admin/config/admin-axios.config'


class PurchasesRepository {
    async getUtmMarks(name){
        const utmMarks = await http.get('users/utm_marks',{
            params:{
                name: name
            }
        })
        return utmMarks.data[0]
    }

    async getPurchasesPage(params){
        const purchasesPage = await http.get('users/purchases',{
            params: params
        })

        return purchasesPage.data
    }

    async getPurchasePrice(params){
        const purchasesPrice = await http.get('users/purchases/total_price',{
            params: params
        })

        return purchasesPrice.data
    }

}

export const purchasesRepository = new PurchasesRepository()
