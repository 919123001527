<template>
  <div class="purchases-filters">
    <range-date-picker v-model="filters.dateRange" @change="updateFilter"/>
    <select-input placeholder="UTM source"
                  v-model="filters.utm_source"
                  :options="selectOptions.utm_source"
                  @change="updateFilter"/>
    <select-input placeholder="UTM medium"
                  v-model="filters.utm_medium"
                  :options="selectOptions.utm_medium"
                  @change="updateFilter"/>
    <select-input placeholder="UTM content"
                  v-model="filters.utm_content"
                  :options="selectOptions.utm_content"
                  @change="updateFilter"/>
    <select-input placeholder="UTM campaign"
                  v-model="filters.utm_campaign"
                  :options="selectOptions.utm_campaign"
                  @change="updateFilter"/>
  </div>
</template>

<script>
import RangeDatePicker from "./filterInputs/RangeDatePicker";
import SelectInput from "./filterInputs/SelectInput";
import {purchasesService} from "../../purchases.service";
import {timeDate} from "../../../../../utils/timeDate";
export default {
  name: "PurchasesFilters",
  components: {
    RangeDatePicker,
    SelectInput
  },
  data() {
    return {
      selectOptions: {
        utm_source: [],
        utm_medium: [],
        utm_campaign: [],
        utm_content: []
      },

      filters: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        dateRange: [timeDate.getFirstDayOfTheCurrentMonth(), timeDate.toLastTime(new Date())]
      }
    }
  },
  mounted() {
    const paramsName = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']
    paramsName.forEach(name => {
      purchasesService.getUtmMarks(name).then(data => {
        this.selectOptions[name] = data
      })
    })
  },
  methods: {
    updateFilter() {
      this.$emit('updateFilter', this.filters)
    }
  }
}
</script>

<style lang="scss" scoped>
.purchases-filters {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
</style>