<template>
  <div class="user-course-table">
    <div class="user-course-table__title">
      <img src="@/assets/img/admin/users/icon-mortarboard.svg" alt="icon">
      <span>Курсы пользователя</span>
    </div>
    <el-table class="user-course-table"
              :data="dataCourses"
              style="width: 100%">
      <el-table-column label="Название курса"
                       width="445px">
        <template slot-scope="props">
          <div class="user-course-table__show-courses-item">
            <img v-if="props.row.addedManually" src="@/assets/img/admin/users/icon-admin.svg" alt="icon">
            <i v-else class="user-course-table__show-courses-delimiter"/>
            {{ props.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="Цена"
          width="250px">
        <template slot-scope="props">
          <span v-if="props.row.price !== null && !props.row.addedManually">{{ props.row.price / 100 }} &#8381;</span>
          <span v-else>Добавлен вручную</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Дата покупки"
          width="160px">
        <template slot-scope="props">
          {{ new Date(props.row.purchaseDate * 1000).toLocaleDateString() }}
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
export default {
  name: "UserCourseTable",
  props:{
    dataCourses: {type: Array}
  }
}
</script>

<style lang="scss">
.user-course-table {

  &__title {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    margin-top: 32px;

    transition: 0.2s;

    span {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #1454F2;

      margin-left: 12px;
    }
  }

  &__show-courses-item {
    display: flex;
    align-items: center;

    margin-bottom: 4px;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
    color: #121212;

    img {
      margin-left: -3px;
      margin-right: 6px;
    }
  }

  &__show-courses-delimiter {
    margin-right: 12px;
    width: 5px;
    min-width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #1454F2;
  }

  .el-table {
    padding-left: 14px;
    background: #FBFBFB;
    transition: all 0.2s;
    tr {
      color: #121212;
      background: #FBFBFB;
      transition: all 0.2s;
    }

    td {
      border-bottom: none;
      padding: 4px 0 6px 0;
    }
    &::before {
      background: #FBFBFB;
    }
  }

  thead {
    th {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #121212;
      background: #FBFBFB;
      border-bottom: none !important;
      transition: all 0.2s;
      padding: 4px 0;
    }
  }
}
</style>