<template>
<div class="purchases-wrapper">
  <div class="purchases-wrapper__title">
    Покупки
    <transition appear name="el-fade-in">
      <span v-if="!loading && quantityPurchases">(Найдено {{quantityPurchases}} с покупками на сумму {{ totalPriceFormat }} рублей)</span>
    </transition>
  </div>

  <purchases-filters @updateFilter="updateFilter"/>
  <purchases-table :data-table="purchasesData"
                   v-loading="loading"
                   :pagination="pagination"
                   @updatePagination="getTablePage"
                   :key="tableKey"/>
</div>
</template>

<script>

import PurchasesFilters from "./components/purchasesFilters/PurchasesFilters";
import PurchasesTable from "./components/purchasesTable/PurchasesTable";

import {purchasesService} from "./purchases.service";
import {timeDate} from "../../../utils/timeDate";
export default {
  name: "PurchasesPageWrapper",
  components:{
    PurchasesFilters,
    PurchasesTable
  },
  data(){
    return {
      loading: false,
      purchasesData:[],
      filterData: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        dateRange: [timeDate.getFirstDayOfTheCurrentMonth(), timeDate.toLastTime(new Date())]
      },
      pagination:{
        limit: 20,
        page: 1,
        total:0
      },
      totalPrice: null,
      tableKey: 0
    }
  },
  mounted() {
    this.getTablePage()
  },
  computed:{
    quantityPurchases(){
      const quantity = this.pagination.total

      if(quantity%10 >= 2 && quantity%10 <= 4)
        return quantity + ' ' + ' пользователя'
      else if(quantity%10 >= 5 && quantity%10 <= 9 || quantity >= 10 && quantity <= 20)
        return quantity + ' ' + ' пользователей'
      else if(quantity%10===1)
        return quantity + ' пользователь'
      return quantity + ' ' + ' пользователей'
    },
    totalPriceFormat(){
      return Intl.NumberFormat('ru-RU').format(this.totalPrice/100)
    }
  },
  methods:{
    getTablePage(){
      this.loading = true
      purchasesService.getPurchasesPage(this.filterData, this.pagination).then((data)=>{

        data.data.forEach(infoUser=>{
          infoUser.utmMarks.sort((a,b)=>{
           return  a.name < b.name ?  1 :  -1
          })
        })

        this.purchasesData = data.data
        this.pagination.total = data.total

        ++this.tableKey
      }).finally(()=>{
        this.loading = false
      })

      purchasesService.getPurchasePrice(this.filterData).then((price)=>{
        this.totalPrice = price.totalPrice
      })
    },

    updateFilter(newFilters){
      this.filterData = newFilters
      this.pagination.page = 1
      this.getTablePage()
    },
  }
}
</script>

<style lang="scss" scoped>
.purchases-wrapper {

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    span {
      font-family: Raleway, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      margin-left: 15px;
    }
  }
}
</style>