export const timeDate = {
    toLocaleDateTime: function(timestamp) {
      return new Date(timestamp)
        .toLocaleString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false
        })
        .replaceAll("/", ".");
    },
    getCurrentDayTs() {
        return new Date(new Date().toDateString()).getTime()
    },
    toLocaleDate: function(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleDateString() : "";
    },
    toLocaleDateYear: function(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        return (
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2)
        );
      } else return "";
    },
    getAge: function(date) {
      const ageDifMs = Date.now() - new Date(date).getTime();
      const ageDate = new Date(ageDifMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age > 0 ? age : 1;
    },
    getFirstDayOfTheCurrentMonth: function() {
      const currentDay = new Date();
      return new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        1
      ).getTime();
    },
    getFirstDayOfTheCurrentMonthByUTC: function() {
      const currentDay = new Date();
      return new Date(
        Date.UTC(currentDay.getUTCFullYear(), currentDay.getUTCMonth(), 1)
      ).getTime();
    },
    toLastTime: function(date) {
      return Math.floor(
        new Date(new Date(date).setHours(23, 59, 0, 0)).getTime()
      );
    },
    getStartTimeFromDate: function(date) {
      return new Date(date).setHours(0, 0, 0);
    },
    getStartTimeFromDateByUTC: function(date) {
      return (
        new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
              0
          )
        ).getTime()
      );
    },
    getDateDiffString: function(date1, date2) {
      const DATE_COUNT_IN_YEAR = 365;
      const DATE_COUNT_IN_MONTH = 30;
      const DATE_COUNT_IN_WEEK = 7;

      const dateDiff = Math.abs(
        Math.floor((date2 - date1) / (1000 * 3600 * 24))
      );

      if (dateDiff >= DATE_COUNT_IN_YEAR) {
        const yearsCount = Math.floor(dateDiff / DATE_COUNT_IN_YEAR);
        return `${yearsCount} ${yearsCount > 1 ? "years" : "year"} ago`;
      } else if (dateDiff >= DATE_COUNT_IN_MONTH) {
        const monthsCount = Math.floor(dateDiff / DATE_COUNT_IN_MONTH);
        return `${monthsCount} ${monthsCount > 1 ? "months" : "month"} ago`;
      } else if (dateDiff >= DATE_COUNT_IN_WEEK) {
        const weeksCount = Math.floor(dateDiff / DATE_COUNT_IN_WEEK);
        return `${weeksCount} ${weeksCount > 1 ? "weeks" : "week"} ago`;
      } else if (dateDiff >= 1) {
        return `${dateDiff} ${dateDiff > 1 ? "days" : "day"} ago`;
      } else {
        if (date1 >= this.getStartTimeFromDate(Date.now())) return "today";
        else return "1 day ago";
      }
    }
};
