<template>
  <el-date-picker
      class="date-range-picker"
      popper-class="date-picker__dropdown"
      :disabled="disabled"
      v-model="dates"
      type="daterange"
      @change="emitInput"
      :picker-options="pickerOptions"
      :clearable="clearable"
      format="dd.MM.yyyy"
      value-format="timestamp"
      start-placeholder="Начало"
      end-placeholder="Конец">
    <div slot="range-separator" class="range-separator"></div>
  </el-date-picker>

</template>

<script>
export default {
  name: "RangeDatePicker",
  props: {
    value: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.dates = this.value
  },
  data() {
    return {
      dates: [],

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    emitInput: function () {
      if (this.dates === null) this.$emit('clear')
      this.$emit('input', this.dates)
      this.$emit('change', this.dates)
    }
  },
  watch: {
    value() {
      this.dates = this.value
    }
  }
}
</script>

<style lang="scss">

.date-range-picker {
  width: 226px !important;
  height: 52px !important;
  padding: 3px 15px 3px 20px !important;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 100px;

  .el-range-input {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #212121;
  }


  .el-range__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin-right: 5px;
  }

  .el-icon-date:before {
    color: #121212;
    font-size: 17px;
    font-weight: 500;
  }

  .el-range__close-icon {
    display: none;
  }

  .range-separator {
    width: 4px;
    height: 1px;
    margin: 0 5px;
    background: #121212;
  }
}

.date-picker__dropdown {
  max-width: 520px;

  .el-date-range-picker__header div {
    font-family: Lato, sans-serif;
    font-weight: 700;
    color: #505050;
  }

  .el-date-range-picker__content {
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }

  .el-date-table td {
    width: 32px;
    padding: 1px 0;
  }

  .el-date-table td.start-date > div > span,
  .el-date-table td.end-date > div > span,{
    background-color: #1454F2;
  }

  .el-date-table td.in-range div {
    background-color: #E3ECFF;
  }
  .el-date-table__row > td.available.in-range:last-child > div {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .el-date-table__row > td.available.in-range:first-child > div {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .el-date-table td.end-date div {
    margin-right: 1px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .el-date-table td.start-date div {
    margin-left: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .el-date-table td.today span {
    border: 1px solid;
  }
}
</style>